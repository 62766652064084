import { range } from "lodash"
import * as PropTypes from "prop-types"
import Icon from "../icons/icon"
import Star from "../icons/star"

function Rating(props) {
  const { max, rate } = props
  return (
    <>
      {range(max).map(i => (
        <Icon Icon={Star} size="xl" key={i} color={rate > i ? "yellow" : "dimGray"} />
      ))}
    </>
  )
}

Rating.defaultProps = {
  max: 5
}

Rating.propTypes = {
  max: PropTypes.number.isRequired,
  rate: PropTypes.number.isRequired
}

export default Rating
