import styled from "styled-components"
import * as PropTypes from "prop-types"
import Rating from "@/components/Rating"
import Typography from "@/components/styled/Typography"
import Image from "next/image"

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 1rem;
  max-width: none;
  @media (min-width: 500px) and (max-width: 991px) {
    max-width: 315px;
  }
`

const ImageContainer = styled.div`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.dark};
  position: relative;
  min-height: 220px;
  img {
    object-fit: cover;
  }
`

const Opinion = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.greyBlue};
    text-align: center;
    font-weight: 300;
    margin: -2rem 0 1rem;
    text-align: justify;
    text-justify: inter-word;
  }
`

const Position = styled(Typography)`
  margin-bottom: 1rem;
`

const Content = styled.div`
  padding: 1rem 0;
  text-align: center;
`

const Name = styled(Typography)`
  && {
    margin: 0;
    font-weight: 500;
  }
`

export default function Testimonial(props) {
  const { testimonial } = props
  const { name, content, image, position, rating } = testimonial

  return (
    <Wrapper>
      {image && (
        <ImageContainer>
          <Image src={image} alt="Padel Mirasol" fill />
        </ImageContainer>
      )}
      <Content>
        <Name variant="body">{name}</Name>
        <Position variant="small">{position}</Position>
        <Rating rate={rating} />
      </Content>
      <Opinion variant="small">{content}</Opinion>
    </Wrapper>
  )
}

Testimonial.propTypes = {
  testimonial: PropTypes.object.isRequired
}
